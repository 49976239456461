/* E360-UI Fonts 1.2.0 */
@font-face {
	font-family: "Proxima Nova";
  src: url("../fonts/proximanova-regular.woff2") format("woff2"),
      url("../fonts/proximanova-regular.woff") format("woff"),
      url("../fonts/proximanova-regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova";
  src: url("../fonts/proximanova-medium.woff2") format("woff2"),
      url("../fonts/proximanova-medium.woff") format("woff"),
      url("../fonts/proximanova-medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/proximanova-semibold.woff2") format("woff2"),
      url("../fonts/proximanova-semibold.woff") format("woff"),
      url("../fonts/proximanova-semibold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/proximanova-bold.woff2") format("woff2"),
      url("../fonts/proximanova-bold.woff") format("woff"),
      url("../fonts/proximanova-bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}
/*# sourceMappingURL=e360-fonts.css.map */